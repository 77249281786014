const LabelColour = (props) => {
    switch(props) {
        case 'orange':
            return 'text-orange'
        case 'blue':
            return 'text-blue'
        case 'green':
            return 'text-green'
        default:
            return 'text-orange'
    }
}

const LabelColourDarker = (props) => {
    switch(props) {
        case 'orange':
            return 'text-darkorange'
        case 'blue':
            return 'text-darkblue'
        case 'green':
            return 'text-darkgreen'
        default:
            return 'text-darkorange'
    }
}

const BackgroundColour = (props) => {
    switch(props) {
        case 'orange':
            return 'bg-orange text-white'
        case 'blue':
            return 'bg-blue text-white'
        case 'green':
            return 'bg-green text-white'
        default:
            return 'bg-white text-black'
    }
}

const ButtonColour = (props) => {
    switch(props) {
        case 'orange':
            return 'text-orange hover:text-darkorange'
        case 'blue':
            return 'text-blue hover:text-darkblue'
        case 'green':
            return 'text-green hover:text-darkgreen'
        default:
            return 'text-orange hover:text-darkorange'
    }
}

const ButtonHoverColour = (props, group) => {
    switch(props) {
        case 'orange':
            return group ? 'group-hover:text-orange' : 'hover:text-orange'
        case 'blue':
            return group ? 'group-hover:text-blue' : 'hover:text-blue'
        case 'green':
            return group ? 'group-hover:text-green' : 'hover:text-green'
        default:
            return group ? 'group-hover:text-orange' : 'hover:text-orange'
    }
}

export {
    LabelColour,
    LabelColourDarker,
    BackgroundColour,
    ButtonColour,
    ButtonHoverColour
}