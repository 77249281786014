import React from 'react'
import SbEditable from 'storyblok-react'
import Heading from 'src/components/Heading'
import Button from 'src/components/Button'
import RichText from 'src/utils/RichText'
import { ButtonColour, LabelColour } from 'src/utils/ColourUtilities'
import Hexagons from "src/images/hexagon-orange-top.svg"
import MembersLogin from './MembersLogin'

const ContentCentered = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="bg-white overflow-hidden relative">
                <div className="max-w-7xl mx-auto py-16 lg:py-24 px-6 flex lg:flex-row flex-col justify-between lg:items-center gap-8">
                    <div className={`${blok.flip ? 'lg:w-7/12 ml-auto lg:order-last' : ''}`}>
                        {blok.title && 
                            <Heading size={`${blok.smaller_title_size ? 'h3' : 'h1'}`} className={`${LabelColour(blok.title_colour)} lg:!mb-8 !mb-5`}>{blok.title}</Heading>
                        }
                        <div className={`prose font-light max-w-2xl tracking-tight ${blok.bigger_content_size ? 'lg:text-[1.625rem] text-xl leading-8' : 'lg:text-[1.375rem] text-lg leading-7'}`}>
                            <RichText>{blok.content}</RichText>
                        </div>
                        {blok.button_text && <Button to={blok.button_target.cached_url} className={`${ButtonColour(blok.title_colour)} lg:mt-6 mt-4`}>{blok.button_text}</Button>}
                    </div>
                    {blok.toggle_user_info &&
                        <MembersLogin />
                    }
                </div>
                {!blok.disable_hexagons && 
                    <Hexagons className={`absolute top-0 lg:block hidden w-[600px] ${blok.flip ? '-left-0 scale-x-[-1]' : '-right-0'}`}/>
                }
            </div>        
        </SbEditable>
    )
}

export default ContentCentered
