import React from 'react'
import { render } from "storyblok-rich-text-react-renderer"
import Button from 'src/components/Button'
import Audio from 'src/components/Audio'

const RichText = ({ children }) => {
    return render(children, {
        blokResolvers: {
            'richtext_button' : (props) => <Button to={props.button_target.cached_url} className="!font-medium">{props.button_text}</Button>,
            'audio' : (props) => <Audio blok={props} isInContent/>
        }
    });
}

export default RichText