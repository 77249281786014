import React from 'react'
import SbEditable from 'storyblok-react'
import LocalAudio from './LocalAudio'

const Audio = ({blok, isInContent}) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`bg-white relative max-w-7xl mx-auto`}>
                <div className={`${isInContent ? '' : 'max-w-3xl mr-auto py-16 px-6'}`}>
                    <div className="relative">
                        {blok.local_audio_file?.filename &&
                            <LocalAudio
                                file={blok.local_audio_file?.filename}
                                caption={blok.local_audio_caption}
                                title={blok.local_audio_title}
                                image={blok.local_audio_image}
                                date={blok.local_audio_date}
                            />
                        }
                        {!blok.local_audio_file?.filename && blok.embed_audio &&
                            <div dangerouslySetInnerHTML={{__html: blok.embed_audio}} />
                        }
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default Audio